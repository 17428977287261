// MY INFURA_KEY, SWAP IN YOURS FROM https://infura.io/dashboard/ethereum
// export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
// export const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY;

// // MY ETHERSCAN_ID, SWAP IN YOURS FROM https://etherscan.io/myapikey
// export const ETHERSCAN_KEY = process.env.REACT_APP_ETHERSCAN_KEY;
// export const POLYGONSCAN_KEY = process.env.REACT_APP_POLYGONSCAN_KEY;

// export const NETWORK_CHOICE = process.env.REACT_APP_NETWORK ? process.env.REACT_APP_NETWORK : "polygon";
// // BLOCKNATIVE ID FOR Notify.js:
// // export const BLOCKNATIVE_DAPPID = process.env.REACT_APP_BLOCKNATIVE_DAPPID;
// // export const BLOCKNATIVE_DAPPID = "";

// export const NETWORKS = {
//   localhost: {
//     name: "localhost",
//     color: "#666666",
//     chainId: 31337,
//     blockExplorer: "",
//     rpcUrl: "http://" + (global.window ? window.location.hostname : "localhost") + ":8545",
//   },
//   mainnet: {
//     name: "mainnet",
//     color: "#ff8b9e",
//     chainId: 1,
//     rpcUrl: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
//     blockExplorer: "https://etherscan.io/",
//   },
//   kovan: {
//     name: "kovan",
//     color: "#7003DD",
//     chainId: 42,
//     rpcUrl: `https://kovan.infura.io/v3/${INFURA_KEY}`,
//     blockExplorer: "https://kovan.etherscan.io/",
//     faucet: "https://gitter.im/kovan-testnet/faucet", // https://faucet.kovan.network/
//   },
//   rinkeby: {
//     name: "rinkeby",
//     color: "#e0d068",
//     chainId: 4,
//     rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
//     // rpcUrl: `https://eth-rinkeby.alchemyapi.io/v2/${ALCHEMY_KEY}`,
//     faucet: "https://faucet.rinkeby.io/",
//     blockExplorer: "https://rinkeby.etherscan.io/",
//   },
//   ropsten: {
//     name: "ropsten",
//     color: "#F60D09",
//     chainId: 3,
//     faucet: "https://faucet.ropsten.be/",
//     blockExplorer: "https://ropsten.etherscan.io/",
//     rpcUrl: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
//   },
//   goerli: {
//     name: "goerli",
//     color: "#0975F6",
//     chainId: 5,
//     faucet: "https://goerli-faucet.slock.it/",
//     blockExplorer: "https://goerli.etherscan.io/",
//     rpcUrl: `https://goerli.infura.io/v3/${INFURA_KEY}`,
//   },
//   xdai: {
//     name: "xdai",
//     color: "#48a9a6",
//     chainId: 100,
//     price: 1,
//     gasPrice: 1000000000,
//     rpcUrl: "https://dai.poa.network",
//     faucet: "https://xdai-faucet.top/",
//     blockExplorer: "https://blockscout.com/poa/xdai/",
//   },
//   polygon: {
//     name: "polygon",
//     color: "#2bbdf7",
//     chainId: 137,
//     price: 1,
//     gasPrice: 1000000000,
//     rpcUrl: "https://polygon-rpc.com/",
//     // rpcUrl: ALCHEMY_KEY ? `https://polygon-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}` : `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
//     blockExplorer: "https://polygonscan.com/",
//   },
//   mumbai: {
//     name: "mumbai",
//     color: "#92D9FA",
//     chainId: 80001,
//     price: 1,
//     gasPrice: 1000000000,
//     // rpcUrl: "https://rpc-mumbai.maticvigil.com",
//     rpcUrl: ALCHEMY_KEY ? `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_KEY}` : `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
//     faucet: "https://faucet.polygon.technology/",
//     blockExplorer: "https://mumbai.polygonscan.com/",
//   },
//   localOptimismL1: {
//     name: "localOptimismL1",
//     color: "#f01a37",
//     chainId: 31337,
//     blockExplorer: "",
//     rpcUrl: "http://" + (global.window ? window.location.hostname : "localhost") + ":9545",
//   },
//   localOptimism: {
//     name: "localOptimism",
//     color: "#f01a37",
//     chainId: 420,
//     blockExplorer: "",
//     rpcUrl: "http://" + (global.window ? window.location.hostname : "localhost") + ":8545",
//     gasPrice: 0,
//   },
//   kovanOptimism: {
//     name: "kovanOptimism",
//     color: "#f01a37",
//     chainId: 69,
//     blockExplorer: "https://kovan-optimistic.etherscan.io/",
//     rpcUrl: `https://kovan.optimism.io`,
//     gasPrice: 0,
//   },
//   optimism: {
//     name: "optimism",
//     color: "#f01a37",
//     chainId: 10,
//     blockExplorer: "https://optimistic.etherscan.io/",
//     rpcUrl: `https://mainnet.optimism.io`,
//   },
//   localAvalanche: {
//     name: "localAvalanche",
//     color: "#666666",
//     chainId: 43112,
//     blockExplorer: "",
//     rpcUrl: `http://localhost:9650/ext/bc/C/rpc`,
//     gasPrice: 225000000000,
//   },
//   fujiAvalanche: {
//     name: "fujiAvalanche",
//     color: "#666666",
//     chainId: 43113,
//     blockExplorer: "https://cchain.explorer.avax-test.network/",
//     rpcUrl: `https://api.avax-test.network/ext/bc/C/rpc`,
//     gasPrice: 225000000000,
//   },
//   mainnetAvalanche: {
//     name: "mainnetAvalanche",
//     color: "#666666",
//     chainId: 43114,
//     blockExplorer: "https://cchain.explorer.avax.network/",
//     rpcUrl: `https://api.avax.network/ext/bc/C/rpc`,
//     gasPrice: 225000000000,
//   },
//   testnetHarmony: {
//     name: "testnetHarmony",
//     color: "#00b0ef",
//     chainId: 1666700000,
//     blockExplorer: "https://explorer.pops.one/",
//     rpcUrl: `https://api.s0.b.hmny.io`,
//     gasPrice: 1000000000,
//   },
//   mainnetHarmony: {
//     name: "mainnetHarmony",
//     color: "#00b0ef",
//     chainId: 1666600000,
//     blockExplorer: "https://explorer.harmony.one/",
//     rpcUrl: `https://api.harmony.one`,
//     gasPrice: 1000000000,
//   },
//   fantom: {
//     name: "fantom",
//     color: "#1969ff",
//     chainId: 250,
//     blockExplorer: "https://ftmscan.com/",
//     rpcUrl: `https://rpcapi.fantom.network`,
//     gasPrice: 1000000000,
//   },
//   testnetFantom: {
//     name: "testnetFantom",
//     color: "#1969ff",
//     chainId: 4002,
//     blockExplorer: "https://testnet.ftmscan.com/",
//     rpcUrl: `https://rpc.testnet.fantom.network`,
//     gasPrice: 1000000000,
//     faucet: "https://faucet.fantom.network/",
//   },
//   moonbeam: {
//     name: "moonbeam",
//     color: "#53CBC9",
//     chainId: 1284,
//     blockExplorer: "https://moonscan.io",
//     rpcUrl: "https://rpc.api.moonbeam.network", 
//   },
//   moonriver: {
//     name: "moonriver",
//     color: "#53CBC9",
//     chainId: 1285,
//     blockExplorer: "https://moonriver.moonscan.io/",
//     rpcUrl: "https://rpc.api.moonriver.moonbeam.network",
//   },
//   moonbaseAlpha: {
//     name: "moonbaseAlpha",
//     color: "#53CBC9",
//     chainId: 1287,
//     blockExplorer: "https://moonbase.moonscan.io/",
//     rpcUrl: "https://rpc.api.moonbase.moonbeam.network",
//     faucet: "https://discord.gg/SZNP8bWHZq",
//   },
//   moonbeamDevNode: {
//     name: "moonbeamDevNode",
//     color: "#53CBC9",
//     chainId: 1281,
//     blockExplorer: "https://moonbeam-explorer.netlify.app/",
//     rpcUrl: "http://127.0.0.1:9933",
//   }
// };
export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
export const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY;
export const NETWORK_CHOICE = process.env.REACT_APP_NETWORK_CHOICE ? process.env.REACT_APP_NETWORK_CHOICE : "polygon";
console.log(`using network ${NETWORK_CHOICE}`);
let testnetUrl;
if (!ALCHEMY_KEY) {
  if (!INFURA_KEY) {
    testnetUrl = "https://rpc-mumbai.maticvigil.com";
  } else {
    testnetUrl = `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`;
  }
} else {
  testnetUrl = `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_KEY}`;
}
let mainnetUrl;
if (!ALCHEMY_KEY) {
  if (!INFURA_KEY) {
    mainnetUrl = "https://polygon-rpc.com/";
  } else {
    mainnetUrl =`https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`;
  }
} else {
  mainnetUrl = `https://polygon-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`;
}
export const NETWORKS = {
  polygon: {
    name: "polygon",
    color: "#2bbdf7",
    chainId: 137,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: mainnetUrl,
    blockExplorer: "https://polygonscan.com/",
  },
  mumbai: {
    name: "mumbai",
    color: "#92D9FA",
    chainId: 80001,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: testnetUrl,
    faucet: "https://faucet.polygon.technology/",
    blockExplorer: "https://mumbai.polygonscan.com/",
  },
}


export const NETWORK = chainId => {
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId) {
      return NETWORKS[n];
    }
  }
};
